<template>
  <div class="page-box">
    <div class="nav-fixed-box">
      <Nav class="nav-fixed" :navFixed="navFixed"  @click.native="scrollView($event)"></Nav>
    </div>
    <el-scrollbar ref="view-box" style="position: relative;flex: 1;height:100%;box-sizing: border-box;">
      <div class="view-box">
        <p style="font-size:26pt; line-height:175%; margin:0pt 0pt 10pt; text-align:center"><a
            name="_055-1599121612466"></a><span
            style="color:#333333;  font-size:26pt; font-weight:bold">应用稳定性标准</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_236-1599470039769"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_766-1599470039781"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="1"><a name="_234-1599470039784"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">一、 背景</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_117-1599470039786"></a><span
            style="color:#595959;  font-size:12pt">Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_945-1599470315283"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="2"><a name="_677-1599470039789"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">二、 适用范围</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_400-1599470039792"></a><span
            style="color:#595959;  font-size:12pt">本标准适用于Android平台各类应用的稳定性衡量与判断。应用类型涉及：视频音乐、通讯社交、摄影美图、新闻阅读、购物优惠、生活服务、实用工具、教育学习、系统工具、金融理财、旅游出行、娱乐消遣、育儿母婴、健康美容、效率办公等,包含但不限于OPPO，VIVO，MI应用商店上架的所有应用。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_041-1599470316103"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="3"><a name="_680-1599470039798"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">三、 术语、定义、缩略语</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_964-1599470039797"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">应用崩溃</span><span
            style="color:#595959;  font-size:12pt">：应用在使用过程中程序出现异常无法继续运行导致应用程序闪退。</span></p>
        <p style="font-size:12pt; line-height:175%; margin:0pt 0pt 10pt; text-indent:0pt"><span
            style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
          </span><a name="_448-1599470039800"></a><span
            style="color:#595959;  font-size:12pt; font-weight:bold">应用无响应</span><span
            style="color:#595959;  font-size:12pt">：应用程序在使用过程中程序卡住，用户操作应用无反应，弹出应用无响应的选择框，等待或退出应用程序。</span>
        </p>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_092-1599470317261"></a><span
            style="font-size:11pt">&#xa0;</span></p>
        <p style="font-size:17pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="4"><a name="_087-1599470039805"></a><span
            style="color:#409eff;  font-size:17pt; font-weight:bold">四、 稳定性标准</span></p>
        <p style="font-size:14pt; line-height:175%; margin:0pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="4-1"><a name="_040-1599470039804"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.1 UI遍历测试</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">测量应用UI遍历稳定性</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">应用在手机上安装</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">UI遍历3小时，不出现应用崩溃和应用无响应</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:14pt; line-height:175%; margin:10pt 0pt 10pt;margin-top: 20pt;" class="nav-title" id="4-2"><a name="_890-1599470039821"></a><span
            style="color:#333333;  font-size:14pt; font-weight:bold">4.2 压力测试</span></p>
        <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt">
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">标准描述</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">测量应用压力测试稳定性</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">预置条件</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">应用在手机上安装</span></p>
            </td>
          </tr>
          <tr style="height:30pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">判定标准</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">Monkey测试3小时，不出现应用崩溃和应用无响应</span></p>
            </td>
          </tr>
          <tr style="height:14pt">
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:12pt; line-height:175%;"><span
                  style="color:#595959;  font-size:12pt">需考虑的特殊事项</span></p>
            </td>
            <td
              style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding:10pt; vertical-align:top">
              <p style="font-size:11pt; line-height:175%;"><span
                  style="font-size:11pt">&#xa0;</span></p>
            </td>
          </tr>
        </table>
        <p style="font-size:11pt; line-height:175%; margin:0pt 0pt 10pt"><a name="_925-1599470039842"></a><span
            style="font-size:11pt">&#xa0;</span></p>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Nav from "@/components/documentsBase/Nav";
export default {
  components: { Nav },
  metaInfo: {
    title:'ITGSA | 移动智能终端生态联盟 | 应用稳定性标准',
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,生态联盟,稳定性,金标认证'
      },
      {
        name: 'description',
        content: 'Android应用的稳定性是构建Android应用体验的重要一环，直接影响用户体验，本标准旨在构建一套Android应用稳定性测试衡量方法，牵引生态内所有应用的稳定性质量改进，构建共同的稳定和体验良好的应用生态。'
      },
    ]
  },
  created() {},
  data() {
    return {
      navFixed: [
          {
            name: "一、 背景",
            isActive:null,
            class:'#1',
          },
          {
            name: "二、 适用范围",
            isActive:null,
            class:'#2',
          },
          {
            name: "三、 术语、定义和缩略语",
            isActive:null,
            class:'#3',
          },
          {
            name: "四、 稳定性标准",
            isActive:null,
            class:'#4',
            child:[
              {
                name: "4.1 UI遍历测试",
                isActive:null,
                class:'#4-1',
              },
              {
                name: "4.2 压力测试",
                isActive:null,
                class:'#4-2',
              },
            ],
          },
        ],
    };
  },
  mounted() {
    $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
    this.scrollContainer = this.$refs['view-box'].wrap;  // 将 el-scrollbar 的 wrap 对象找出来，指定给 scroll-container 
    this.scrollContainer.addEventListener('scroll',utils.throttle(this.handleScroll,100,200));
  },
  methods: {
    scrollView(event){
      if($(event.target).attr("view")){
        this.$refs['view-box'].wrap.scrollTop = $($(event.target).attr("view")).position().top ;
      }
    },
    //滚动检查
    handleScroll() {
      //获取滚动时的高度
      let scrollTop = this.scrollContainer.scrollTop;
      if(scrollTop){
        for(let i=($(".nav-title").length-1);i>=0;i--){
          if (scrollTop > $(".nav-title").eq(i).position().top-30) { 
            $('.nav-fixed-box .name').css("color","#000");
            $('.nav-fixed-box .name').eq(i).css("color","#1989fa");
            this.awaitScroll($('.nav-fixed-box .name').eq(i).position().top);
            break;
          }
        }
      }else{
        $('.nav-fixed-box .name').css("color","#000");
        $('.nav-fixed-box .name').eq(0).css("color","#1989fa");
        this.awaitScroll(0);
      }
    },
    //同步目录滚动
    awaitScroll(top) {
      $('.nav-fixed-box').animate({
        scrollTop:top
      },300);
    },
  },
  destroyed() {
    this.scrollContainer.removeEventListener('scroll', this.handleScroll)
  },
};
</script>

<style scoped lang="scss">
table{
  td{
    padding: 10pt;
  }
}
/*主体*/
.page-box{
  position: relative;
  display: flex;
  .nav-fixed-box{
    width:300px;
    height: 100%;
    padding: 40px 15px;
    overflow-y: scroll;
    .nav-fixed{
      position: relative;
    }
  }
  .view-box{
    position: relative;
    padding: 60px;
  }
}
@media screen and (max-width: 800px) {
    .nav-fixed-box {
      display: none;
    }
}
</style>
